
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";

@Component
export default class CleanerDownloadDialog extends Vue {
  @PropSync("show", { type: Boolean }) showDialog!: boolean;
  @Prop({ type: String }) readonly downloadUrl: string | null | undefined;
  @Prop({ type: String }) readonly logDownloadUrl: string | null | undefined;
  @Prop({ type: String }) readonly logText: string | null | undefined;
}
