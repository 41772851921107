
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';

@Component
export default class ErrorDialog extends Vue {
  @PropSync('show', { type: Boolean }) showDialog!: boolean;
  @Prop({ type: String }) readonly errorMessage: string | null | undefined;

  close(): void {
    this.showDialog = false;
    this.$emit('close');
  }
}
