
import { Component, Vue, Prop } from "vue-property-decorator";
import CleanerDownloadDialog from "@/components/CleanerDownloadDialog.vue";
import ErrorDialog from "@/components/ErrorDialog.vue";
import { FileInput, FileInputItems } from "@/models/FileInput";

export interface PipelineStageResult {
  success: boolean;
  errorMessage?: string;
  id?: string;
  url?: string;
  data?: { [key: string]: string };
  logText?: string;
}

@Component({ components: { CleanerDownloadDialog, ErrorDialog } })
export default class XerUploadForm extends Vue {
  @Prop({ type: String }) readonly title: string | null | undefined;
  @Prop({ type: String }) readonly helpText: string | null | undefined;
  @Prop({ type: String }) readonly accept: string | null | undefined;
  @Prop({ type: Boolean, required: true, default: false }) readonly multiFile!: boolean;
  @Prop({ type: Boolean, required: true, default: false })
  readonly multiProject!: boolean;
  @Prop({ type: Function }) readonly validateFiles: (
    f: FileInput[]
  ) => boolean | null | undefined;
  @Prop({ type: Function, required: true }) readonly fileUploader: (
    f: FileInput[]
  ) => Promise<PipelineStageResult>;
  @Prop({ type: Function, required: true }) readonly fileProcessor: (
    id: string,
    parms: Record<string, boolean | string>
  ) => Promise<PipelineStageResult>;
 @Prop({ type: Function, required: true }) readonly logFileDownloader: (
    logUrl: string,
  ) => Promise<PipelineStageResult>;
  uploadItems = new FileInputItems(true);
  loading = false;
  loadingMessage = "";
  downloadUrl: string | null = null;
  logDownloadUrl: string | null = null;
  logText: string | null = null;
  message = "";
  dialog = false;
  uploadId = "";
  processingData?: { [key: string]: string };
  showProjectHelpTooltips = false;
  showError = false;
  errorMessage = "";

  cleanerParameters = [
    { name: "Calendars", value: false, par: "calendarsSelected" },
    { name: "Resources", value: false, par: "resourcesSelected" },
    { name: "Cost Accounts", value: false, par: "costAccountsSelected" },
    { name: "Roles", value: false, par: "rolesSelected" },
    { name: "Resource Curves", value: false, par: "resourceCurvesSelected" },
    { name: "Activity Codes", value: false, par: "activityCodesSelected" },
    { name: "Project Codes", value: false, par: "projectCodesSelected" },
    { name: "Resource Codes", value: false, par: "resourceCodeSelected" },
    { name: "Resource Shifts", value: false, par: "resourceShiftsSelected" },
    { name: "User Defined Fields", value: false, par: "userDefinedFieldsSelected" },
  ];

  get formWidth(): number {
    return 800;
  }

  get canUpload(): boolean {
    if (this.uploadItems.validCount === 0 || !this.uploadItems.allValid || this.loading) {
      return false;
    }
    if (typeof this.validateFiles === "function") {
      return this.validateFiles(this.uploadItems.items);
    }
    return true;
  }

  get downloadReady(): boolean {
    return (
      !this.loading &&
      typeof this.downloadUrl === "string" &&
      this.downloadUrl.length > 0 &&
      typeof this.logDownloadUrl === "string" &&
      this.logDownloadUrl.length > 0
    );
  }

  mounted(): void {
    this.$ai.trackPageView();
  }

  onErrorClosed(): void {
    this.resetState();
  }

  private resetState(): void {
    this.loading = false;
    this.downloadUrl = null;
    this.logDownloadUrl = null;
    this.logText = null;
    this.loadingMessage = "";
    this.uploadId = "";
    this.errorMessage = "";
    this.cleanerParameters = [
      { name: "Calendars", value: true, par: "calendarsSelected" },
      { name: "Resources", value: true, par: "resourcesSelected" },
      { name: "Cost Accounts", value: true, par: "costAccountsSelected" },
      { name: "Roles", value: true, par: "rolesSelected" },
      { name: "Resource Curves", value: true, par: "resourceCurvesSelected" },
      { name: "Activity Codes", value: true, par: "activityCodesSelected" },
      { name: "Project Codes", value: true, par: "projectCodesSelected" },
      { name: "Resource Codes", value: true, par: "resourceCodeSelected" },
      { name: "Resource Shifts", value: true, par: "resourceShiftsSelected" },
      { name: "User Defined Fields", value: true, par: "userDefinedFieldsSelected" },
    ];
  }

  async cleanXerFile(): Promise<void> {
    if (!this.canUpload) {
      // This should never happen, but just in case
      return;
    }

    let obj: Record<string, boolean | string> = this.cleanerParameters.reduce(
      (o, key) => ({ ...o, [key.par]: key.value }),
      {}
    );

    obj = { ...obj, ...{ projectNumber: this.uploadItems.items[0].inputName } };

    // ID should be generated on the API side, not client
    // Otherwise, API is open to security issues because client
    // can send any ID it wants.
    this.loading = true;
    this.downloadUrl = null;
    this.logDownloadUrl = null;
    this.loadingMessage = "Uploading xer file...";

    try {
      const uploadResult = await this.fileUploader(this.uploadItems.items);
      if (!uploadResult.success || typeof uploadResult.id !== "string") {
        this.$ai.trackTrace({
          message: `Error uploading file: ${uploadResult.errorMessage}`,
          severityLevel: 3,
        });
        console.log(uploadResult.errorMessage);
        this.displayError("Error uploading file.");
        return;
      }

      this.loadingMessage = "Processing xer file...";
      const processingResult = await this.fileProcessor(uploadResult.id, obj);
      if (!processingResult.success) {
        this.$ai.trackTrace({
          message: `Error processing file: ${processingResult.errorMessage}`,
          severityLevel: 3,
        });
        this.displayError("Error processing file.");
        return;
      }
      
      this.downloadUrl = processingResult.data["cleanFileUrl"];
      this.logDownloadUrl = processingResult.data["logFileUrl"];
      this.loadingMessage = "Downloading log file...";
      const logFileProcessingResult = await this.logFileDownloader(this.logDownloadUrl);
      if (!logFileProcessingResult.success) {
        this.$ai.trackTrace({
          message: `Error downloading log file: ${processingResult.errorMessage}`,
          severityLevel: 3,
        });
        this.displayError("Error downloading log file");
        return;
      }
      this.uploadId = uploadResult.id;
      this.processingData = processingResult.data;
      this.logText = logFileProcessingResult.logText;

    } catch (error) {
      // TODO: do something usefull here
      this.displayError("General error uploading file.");
      this.$ai.trackException({ exception: error, severityLevel: 3 });
    } finally {
      this.loading = false;
      this.uploadItems.clear();
      this.dialog = this.downloadReady;
    }
  }

  private displayError(error: string): void {
    this.errorMessage = `${error} Please try again later.`;
    this.showError = true;
  }
}
