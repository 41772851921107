
import { Component, Vue } from "vue-property-decorator";
import XerUploadForm, {PipelineStageResult } from "@/components/XerCleaner/XerUploadForm.vue";
import authentication from "@/authentication";
import { FileInput } from "@/models/FileInput";
import { validateFile } from "@/utils";

@Component({ components: { XerUploadForm } })
export default class P62IUploadForm extends Vue {
   public baseurl: string|undefined = process.env.VUE_APP_API_BASE_URL || 'http://localhost:7071/api';
  mounted(): void {
    this.$ai.trackPageView();
  }

  validateFiles(files: FileInput[]): boolean {
    return files.every((f) => validateFile(f.inputFiles));
  }

  async fileUploader(f: FileInput[]): Promise<PipelineStageResult> {
    if (f.length === 0) {
      return {
        success: false,
        errorMessage: "Invalid number of files provided",
      };
    }
    try {
      const sasResponse = await this.$axios.get(
        `${this.baseurl}/GetUploadSasCleaner`,
        {
          headers: {
            Authorization: `Bearer ${authentication.token}`,
          },
        }
      );
      if (sasResponse && sasResponse.status === 200) {
        const { url } = sasResponse.data;
        const id = sasResponse.data.id;

        const putConfig = {
          headers: {
            "x-ms-blob-type": "BlockBlob",
            "x-ms-version": "2019-07-07",
            "x-ms-date": new Date().toUTCString(),
            "Content-Type": "text/plain",
          },
        };
        console.log(f[0].inputFiles[0])
        await this.$axios.put(url, f[0].inputFiles, putConfig);
        return { success: true, id };
      } else {
        this.$ai.trackTrace({
          message: `Bad response from GetUploadSasCleaner: ${sasResponse.status}`,
          severityLevel: 3,
        });
        // TODO: Show error
        return {
          success: false,
          errorMessage: `Bad response from GetUploadSasCleaner: ${sasResponse.status}`,
        };
      }
    } catch (error) {
      return { success: false, errorMessage: `${error}` };
    }
  }

  async fileProcessor(
    id: string,
    data: Record<string,boolean|string>
  ): Promise<PipelineStageResult> {
    const config = {
      headers: { Authorization: `Bearer ${authentication.token}` },
    };
    try {
      const cleanerResponse = await this.$axios.post(
        `${this.baseurl}/Clean/` + id,
        data,
        config
      );
      return { success: true, data: cleanerResponse.data, id };
    } catch (error) {
      return { success: false, errorMessage: `${error}` };
    }
  }
  
  async logFileDownloader(
    logUrl: string,
  ): Promise<PipelineStageResult> {
    try {
      const cleanerResponse = await this.$axios.get(
       logUrl
      );
      return { success: true, logText: cleanerResponse.data };
    } catch (error) {
      return { success: false, errorMessage: `${error}` };
    }
  }
}
